import React, { useState, useEffect } from 'react';
import ProductModal from '../components/ProductModal';
import FilterSort from '../components/FilterSort';
import ProductGrid from '../components/ProductGrid';
import '../styles/main.css';
import GoBackComponent from '../components/GoBackComponent';

const GrowPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('default');
  const [subcategory, setSubcategory] = useState('All'); // Subcategory state
  const [subcategories, setSubcategories] = useState(['All']); // Dynamic subcategories

  // Fetch products for Merch category
// Fetch products for Merch category and dynamically populate subcategories
useEffect(() => {
  const fetchProducts = async () => {
    const category = 'Grow Supplies';
    const apiUrl = `/api/stripe/getProducts?category=${encodeURIComponent(category)}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setProducts(data);
      setFilteredProducts(data); // Initialize with all products

      // Dynamically populate subcategories for Merch products
      const categoryProducts = data.filter(product => product.category === category);

      // Extract unique subcategories from product metadata
      const uniqueSubcategories = Array.from(new Set(categoryProducts.map(product => product.subCategory).filter(Boolean)));

      // Set subcategories with "All" as the first option
      setSubcategories(['All', ...uniqueSubcategories]);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  fetchProducts();
}, []); // Only run once when the component is mounted


  // Filter and sort products when subcategory or sort option changes
  useEffect(() => {
    let updatedProducts = products.filter((product) => {
      // Filter products based on subcategory, without using metadata
      const matchesSubcategory = subcategory === 'All' || product.subCategory === subcategory;
      return matchesSubcategory;
    });
  
    // Apply sorting
    if (sortOption === 'lowToHigh') {
      updatedProducts.sort((a, b) => (a.default_price?.unit_amount || 0) - (b.default_price?.unit_amount || 0));
    } else if (sortOption === 'highToLow') {
      updatedProducts.sort((a, b) => (b.default_price?.unit_amount || 0) - (a.default_price?.unit_amount || 0));
    }
  
    setFilteredProducts(updatedProducts);
  }, [products, sortOption, subcategory]);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="main-container">
      <GoBackComponent />
      <h3 className="main-products-title">Grow Supplies</h3>

      <FilterSort
        sortOption={sortOption}
        setSortOption={setSortOption}
        subcategory={subcategory} // Subcategory state
        setSubcategory={setSubcategory} // Set subcategory
        subcategories={subcategories} // Dynamic subcategories
      />

      <ProductGrid products={filteredProducts} openModal={openModal} />

      {selectedProduct && (
        <ProductModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Product Details"
          product={selectedProduct}
        />
      )}
    </div>
  );
};

export default GrowPage;
