import React from 'react';
import GoBackComponent from '../components/GoBackComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Ensure FontAwesome is properly imported
import { faInstagram } from '@fortawesome/free-brands-svg-icons'; // Import the Instagram icon
import '../styles/about.css';

const AboutPage = () => {
  return (
    <div className="about-container">
      <GoBackComponent></GoBackComponent>
      <h1 className="about-title">About Us</h1>

{/* Image section */}
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="image">
    <img
      src="/images/magic_mushroom.png" // Use direct path from the public folder
      alt="Mushroom"
      style={{ width: '375px', height: '400px' }}
    />
  </div>
</div>


      
      <br />
      <br />

      {/* Welcome Section */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Welcome to DBMyco Supply!
      </h4>
      <p style={{ textAlign: 'left' }}>
        Your one-stop destination for high-quality mushrooms and grow kits designed to help you cultivate delicious mushrooms right in the comfort of your home.
      </p>
      
      <br />

      {/* Our Story */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Our Story
      </h4>
      <p style={{ textAlign: 'left' }}>
        Founded in 2023, DBMyco Supply, was born out of a passion for mushrooms and a desire to share the joy of growing and enjoying mushroom products. 
        What started as a small hobby has blossomed into a thriving business dedicated to delivering premium grow kits and supplies to our valued customers.
      </p>
      
      <br />

      {/* Why Choose Us */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Why Choose Us
      </h4>
      <ul style={{ textAlign: 'left' }}>
        <li>
          <strong><span style={{ color: '#4fa396' }}>Quality Assurance:</span></strong> We source our products and grow kits from trusted suppliers, ensuring that every product meets our high standards of quality and freshness.
        </li>
        <li>
          <strong><span style={{ color: '#4fa396' }}>Expert Guidance:</span></strong> Whether you're a beginner or an experienced cultivator, our team of experts is here to provide guidance, tips, and resources to help you succeed.
        </li>
        <li>
          <strong><span style={{ color: '#4fa396' }}>Made to Order:</span></strong> Every purchase is custom-made to order, ensuring the highest quality and proper care of your products. Contact us for custom or large orders.
        </li>
        <li>
          <strong><span style={{ color: '#4fa396' }}>Customer Satisfaction:</span></strong> Your satisfaction is our top priority. We strive to provide excellent customer service and support throughout your mushroom-growing journey.
        </li>
      </ul>
      
      <br />

      {/* Our Products */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Our Products
      </h4>
      <p style={{ textAlign: 'left' }}>
        <strong style={{ color: '#4fa396' }}>Grow Kits:</strong> Start your mushroom-growing adventure with our easy-to-use grow kits. Each kit comes with everything you need to cultivate your own delicious mushrooms at home.
      </p>
      <p style={{ textAlign: 'left' }}>
        <strong style={{ color: '#4fa396' }}>Grow Supplies:</strong> Explore a variety of other grow supplies to enhance your mushroom cultivation experience, including substrate materials, cultivation tools, and more.
      </p>
      <p style={{ textAlign: 'left' }}>
        <strong style={{ color: '#4fa396' }}>Lab Supplies:</strong> Discover a range of lab supplies for mushroom cultivation, such as agar plates, and other laboratory accessories.
      </p>
      
      <br />

      {/* Contact Section */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Get In Touch
      </h4>
      <p style={{ textAlign: 'left' }}>
        Ready to embark on your mushroom-growing journey? Have questions or need assistance? Don't hesitate to reach out to us. Our friendly team is here to help!
      </p>
      <ul style={{ textAlign: 'left' }}>
        <li><strong style={{ color: '#D3AA1A' }}>Email:</strong> <a href="mailto:dbmycosupply@gmail.com" style={{ color: '#4fa396' }}>dbmycosupply@gmail.com</a></li>
        <li style={{ display: 'flex', alignItems: 'center' }}>
          <strong style={{ color: '#D3AA1A', marginRight: '5px' }}>Follow Us:</strong>
          <a href="https://www.instagram.com/dbmycosupply/" style={{ color: '#4fa396', display: 'flex', alignItems: 'center' }}>
            Instagram <FontAwesomeIcon icon={faInstagram} style={{ marginLeft: '5px' }} />
          </a>
        </li>
      </ul>
      
      <br />

      {/* Closing Statement */}
      <h4 style={{ textAlign: 'left', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Thank you!
      </h4>
      <p style={{ textAlign: 'left' }}>
        We appreciate your trust in us as your reliable source for quality products and grow kits. Happy growing!
      </p>
    </div>
  );
};

export default AboutPage;
