import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartProvider } from './contexts/CartContext';
import Header from './components/Header/Header';
import Footer from './components/Footer'; 
import HomePage from './pages/HomePage';
import AllProductsPage from './pages/AllProductsPage';
import GrowPage from './pages/GrowPage';
import LabPage from './pages/LabPage';
import MerchPage from './pages/MerchPage';
import SampleMerchPage from './pages/SampleMerchPage';
import AboutPage from './pages/AboutPage';
import ConsultPage from './pages/ConsultPage';
import SuccessPage from './pages/SuccessPage';
import CancelPage from './pages/CancelPage';
import EmailSent from './pages/EmailSent';
import EmailFail from './pages/EmailFail';
import CartPage from './pages/CartPage';
import './styles/fonts.css';
import './App.css';

function App() {
    return (
        <div className="app-container">
            <CartProvider>
                <Router>
                    <Header />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/all-products" element={<AllProductsPage />} />
                        <Route path="/growsupplies" element={<GrowPage />} />
                        <Route path="/labsupplies" element={<LabPage />} />
                        <Route path="/merch" element={<MerchPage />} />
                        <Route path="/samplemerch" element={<SampleMerchPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/consultation" element={<ConsultPage />} />
                        <Route path="/success" element={<SuccessPage />} />
                        <Route path="/cancel" element={<CancelPage />} />
                        <Route path="/emailsent" element={<EmailSent />} />
                        <Route path="/emailfail" element={<EmailFail />} />
                        <Route path="/cart" element={<CartPage />} />
                    </Routes>
                    <Footer />
                </Router>
            </CartProvider>
        </div>
    );
}

export default App;
