import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useCart } from '../../contexts/CartContext';
import '../../styles/cartsummary.css';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CartSummary = () => {
    const { cartItems } = useCart(); // Get cart items from your context
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [errorMessage, setErrorMessage] = useState(''); // State to manage error messages

    const handleCheckout = async () => {
        if (cartItems.length === 0) {
            setErrorMessage('Your cart is empty.');
            return;
        }

        setLoading(true); // Start loading
        setErrorMessage(''); // Clear any previous errors

        const stripe = await stripePromise; // Wait for Stripe to load

        try {
            const response = await fetch('/api/stripe/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ items: cartItems }), // Send cart items to the server
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }

            const { id } = await response.json(); // Get the session ID from the response

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({
                sessionId: id,
            });

            if (error) {
                console.error('Stripe Checkout error:', error);
                setErrorMessage('Failed to redirect to Stripe Checkout. Please try again.');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            setErrorMessage('Failed to create checkout session. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Calculate the total price of items in the cart
    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    return (
        <div className="cart-summary">
            <h2>Cart Summary</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="summary-item">
                <span>Total Items:</span>
                <span>{cartItems.length}</span>
            </div>
            <div className="tax-item">
                <span>Taxes& Shipping</span>
                <span>Calculated at Checkout</span>
            </div>
            <div className="summary-item">
                <span>Total Price:</span>
                <span>${totalPrice.toFixed(2)}</span>
            </div>
            <button
                className="checkout-button"
                onClick={handleCheckout}
                disabled={loading || cartItems.length === 0}
            >
                {loading ? 'Processing...' : 'Proceed to Checkout'}
            </button>
        </div>
    );
};

export default CartSummary;
