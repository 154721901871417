import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import '../styles/success.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useCart } from '../contexts/CartContext'; // Adjust this path to match the location of your CartContext file
// Import the useCart hook

const SuccessPage = () => {
  const { clearCart } = useCart(); // Destructure clearCart from useCart

  useEffect(() => {
    // Clear the cart when the component mounts
    clearCart();
  }, []); // Empty dependency array to run only once when component mounts

  return (
    <div className="main-success-container">
      <div className="success-container">
        <div className="product-text">
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 style={{ textAlign: 'center', fontFamily: 'Bublest, sans-serif', color: '#D3AA1A' }}>Thank you for your purchase!</h3>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='image'>
                <img src="/images/mushroom_2.png.png" alt="Mushroom" style={{ width: '325px', height: '400px' }} />
            </div>

          </div>
          <br />
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '16px' }}> You will receive an email with your receipt.</p>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '16px' }}> For any questions or concerns regarding your order, please email us at <a href="mailto:dbmycosupply@gmail.com" style={{ color: '#4fa396' }}>dbmycosupply@gmail.com</a></p>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '28px' }}> Happy growing!</p>
          <br/ >
          {/* Add the "Back to Cart" link */}
          <p style={{ textAlign: 'center' }}>
            <Link to="/" style={{ color: '#4fa396', fontFamily: 'Sovereign, sans-serif' }}>  &larr; Home</Link>
          </p>
        </div> 
      </div>
    </div>  
  );
};

export default SuccessPage;