import React from 'react';
import '../styles/goback.css';

const GoBackComponent = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="go-back-container">
      <span onClick={goBack}>&larr;</span> 
      <span onClick={goBack}> Back</span>
    </div>
  );
};

export default GoBackComponent;