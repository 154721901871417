import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/productdropdown.css';

const ProductsDropdown = ({ closeMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref to track dropdown

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    closeMenu();
  };

  // Close dropdown if user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="products-dropdown" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        Products
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <Link to="/all-products" className="dropdown-item" onClick={handleLinkClick}>
            All Products
          </Link>
          <Link to="/growsupplies" className="dropdown-item" onClick={handleLinkClick}>
            Grow Supplies
          </Link>
          <Link to="/labsupplies" className="dropdown-item" onClick={handleLinkClick}>
            Lab Supplies
          </Link>
          <Link to="/merch" className="dropdown-item" onClick={handleLinkClick}>
            Merch
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProductsDropdown;
