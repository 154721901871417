import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../contexts/CartContext';
import ProductsDropdown from './ProductsDropdown';
import '../../styles/header.css';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const { cartItems } = useCart();
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null); // Ref for the menu button

  // Track state change for debugging
  useEffect(() => {
  }, [menuOpen]);

  // Scroll effect for the header
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Close menu when clicking outside, but ignore clicks on the menu button
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ignore clicks on the menu button
      if (menuRef.current && !menuRef.current.contains(event.target) && !menuButtonRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu if clicked outside and not on the button
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // Toggle the menu open/close
  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  return (
    <header className={`header-container ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo-link">
        <Link to="/" className="logo">DB</Link>
      </div>

      {/* Button for menu toggle */}
      <button
        className="menu-toggle"
        ref={menuButtonRef}  // Ref for the button to ignore in outside clicks
        onClick={(e) => {
          e.stopPropagation();  // Prevent conflicts with other click handlers
          toggleMenu();  // Toggle the menu open/close
        }}>
        <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
      </button>

      {/* Navigation links */}
      <nav ref={menuRef} className={`navigation ${menuOpen ? 'active' : ''}`}>
        <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link>
        <ProductsDropdown closeMenu={() => setMenuOpen(false)} />
        <Link to="/about" className="nav-link" onClick={() => setMenuOpen(false)}>About</Link>
        <Link to="/consultation" className="nav-link" onClick={() => setMenuOpen(false)}>Consult</Link>
        <Link to="/cart" className="nav-link" onClick={() => setMenuOpen(false)}>
          <FontAwesomeIcon icon={faShoppingBasket} />
          {cartItems.length > 0 && (
            <span className="item-count">
              {cartItems.reduce((total, item) => total + item.quantity, 0)}
            </span>
          )}
        </Link>
      </nav>
    </header>
  );
};

export default Header;
