import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import '../styles/cancel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const CancelPage = () => {
  return (
    <div className="main-cancel-container"> 
      <div className="cancel-container">
        <div className="product-text">
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 style={{ textAlign: 'center', fontFamily: 'Bublest, sans-serif', color: '#D3AA1A' }}>Oops!</h3>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='image'>
                <img src="/images/mushroom_2.png" alt="Mushroom" style={{ width: '325px', height: '400px' }} />
            </div>

          </div>
          <br />
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '18px' }}> Your payment was canceled.</p>
          <br />
          <br />
          {/* Add the "Back to Cart" link */}
          <p style={{ textAlign: 'center' }}>
            <Link to="/cart" style={{ color: '#4fa396', fontFamily: 'Sovereign, sans-serif' }}>  &larr; Back to Cart</Link>
          </p>
        </div> 
      </div>
    </div>  
  );
};

export default CancelPage;