import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useCart } from '../contexts/CartContext';
import { Carousel } from 'react-responsive-carousel'; // Import the carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import '../styles/productmodal.css';

const ProductModal = ({ isOpen, onRequestClose, contentLabel, product }) => {
  const [selectedPrice, setSelectedPrice] = useState(null); // Handle selected price
  const { addCartItem } = useCart(); // Get addCartItem function from cart context
  const [showNotification, setShowNotification] = useState(false); // Notification state
  const [fadeOut, setFadeOut] = useState(false); // For fade-out effect

  useEffect(() => {
    if (product && product.default_price) {
      setSelectedPrice(product.default_price); // Default to the product's default price
    }
  }, [product]);

  // Update selected price when user chooses a different option
  const handlePriceChange = (price) => {
    setSelectedPrice(price);
  };

  // Handle adding the selected product and price to the cart
  const handleAddToCart = () => {
    if (selectedPrice) {
      addCartItem({
        id: product.id, // Base product ID
        selectedPriceId: selectedPrice.id, // The specific price ID
        name: product.name,
        price: selectedPrice.unit_amount / 100, // Convert cents to dollars
        currency: selectedPrice.currency.toUpperCase(),
        quantity: 1,
        image: product.images[0],
        priceNickname: selectedPrice.nickname, // Nickname for the specific price
        defaultPriceNickname: product.default_price?.nickname, // Nickname for the default price, if exists
      });

      

      // Show the "Item added to cart" notification
      setShowNotification(true);
      setFadeOut(true);

      // After 3 seconds, start fading out
      setTimeout(() => {
        setFadeOut(true);
      }, 2000);

      // After another 0.5 seconds (when fade out is done), hide notification
      setTimeout(() => {
        setShowNotification(false);
      }, 2500);
    }
  };

    // useEffect to trigger slide-in when notification appears
    useEffect(() => {
      if (showNotification) {
        setTimeout(() => {
          setFadeOut(false); // Ensure it's set to false to slide in
        }, 10); // Small delay to ensure the element renders before applying slide-in
      }
    }, [showNotification]);


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="product-modal"
      overlayClassName="product-modal-overlay"
    >
      <div className="modal-content">
        {/* Left: Carousel for images */}
        <div className="modal-body-left">
          <div className="modal-title">
            <h2>{product.name}</h2>
          </div>
          <div className="modal-image-container">
            {/* Carousel */}
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows>
              {product.images
                .concat(
                  product.additional_images
                  ? JSON.parse(product.additional_images) // Parse additional_images safely
                  : []
                )
                .map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Product Image ${index + 1} - ${product.name}`} className="modal-image" />
                  </div>
                ))}
            </Carousel>
          </div>
        </div>

        {/* Right: Price, Description, Select Price, Add to Cart */}
        <div className="modal-body-right">
          <div className="modal-description-container">
            <h3 className="description-title">Item Description</h3>
            <p className="modal-description">{product.description}</p>
          </div>

          {/* Price options */}
          {product.prices && product.prices.length > 1 && (
            <div className="price-options">
              <h3>Select a Price:</h3>
              {product.prices.map((price) => (
                <label key={price.id} className="price-option">
                  <input
                    type="radio"
                    name="price"
                    value={price.id}
                    checked={selectedPrice && selectedPrice.id === price.id}
                    onChange={() => handlePriceChange(price)}
                  />
                  {price.nickname || product.default_price.nickname} - {price.currency.toUpperCase()} {(price.unit_amount / 100).toFixed(2)}
                </label>
              ))}
            </div>
          )}

          {/* Display the selected price */}
          {selectedPrice && (
            <p className="modal-price">
              {selectedPrice.nickname} {selectedPrice.currency.toUpperCase()} {(selectedPrice.unit_amount / 100).toFixed(2)}
            </p>
          )}

          {/* Add to cart button */}
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            Add to Cart
          </button>

          {/* Notification for adding to cart */}
          {showNotification && (
            <div className={`add-to-cart-notification ${fadeOut ? 'slide-out' : 'slide-in'}`}>
              Item added to cart!
            </div>
          )}
        </div>

        {/* Close button */}
        <button className="close-button" onClick={onRequestClose}>
          X
        </button>
      </div>
    </Modal>
  );
};

export default ProductModal;
