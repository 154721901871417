import React, { useState, useEffect } from 'react';
import ProductModal from '../components/ProductModal';
import FilterSortAll from '../components/FilterSortAll';
import ProductGrid from '../components/ProductGrid';
import '../styles/main.css';
import GoBackComponent from '../components/GoBackComponent';

const AllProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('default');
  const [category, setCategory] = useState('All');
  const [subcategory, setSubcategory] = useState('All');
  const [categories, setCategories] = useState(['All']); // Dynamically set categories
  const [subcategories, setSubCategories] = useState(['All']); // Subcategories for current category

  // Fetch products for all categories
  useEffect(() => {
    const fetchProducts = async (category) => {
      const apiUrl = `/api/stripe/getProducts?category=${encodeURIComponent(category)}`;
      try {
        console.log(`Fetching products for category: ${category}`);
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        //console.log(`Fetched products for category ${category}:`, data);

        // Accumulate all products
        setProducts((prevProducts) => [...prevProducts, ...data]);

        // Populate categories dynamically
        const uniqueCategories = Array.from(new Set(data.map(product => product.category)));
        setCategories((prevCategories) => {
          return Array.from(new Set([...prevCategories, ...uniqueCategories]));
        });
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    // Fetch products for each category
    ['Grow Supplies', 'Lab Supplies', 'Merch'].forEach((category) => {
      fetchProducts(category);
    });
  }, []);

  // Filter and sort products
  useEffect(() => {
    console.log('Filtering products by category and subcategory:', category, subcategory);
    console.log('Products before filtering:', products);

    let updatedProducts = products.filter((product) => {
      const matchesCategory = category === 'All' || product.category === category;
      const matchesSubcategory = subcategory === 'All' || product.subCategory === subcategory;
      return matchesCategory && matchesSubcategory;
    });

    // Apply sorting
    if (sortOption === 'lowToHigh') {
      updatedProducts.sort((a, b) => (a.default_price?.unit_amount || 0) - (b.default_price?.unit_amount || 0));
    } else if (sortOption === 'highToLow') {
      updatedProducts.sort((a, b) => (b.default_price?.unit_amount || 0) - (a.default_price?.unit_amount || 0));
    }

    setFilteredProducts(updatedProducts);
  }, [products, sortOption, category, subcategory]);

  // Open the product modal
  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  // Close the product modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  // Update subcategories when category changes
  useEffect(() => {
    if (category !== 'All') {
      const categoryProducts = products.filter(product => product.category === category);
      const uniqueSubcategories = Array.from(new Set(categoryProducts.map(product => product.subCategory).filter(Boolean)));
      setSubCategories(['All', ...uniqueSubcategories]);
    } else {
      setSubCategories(['All']);
    }
  }, [category, products]);

  return (
    <div className="main-container">
      <GoBackComponent />
      <h3 className="main-products-title">All Products</h3>

      <FilterSortAll
        sortOption={sortOption}
        setSortOption={setSortOption}
        category={category}
        setCategory={setCategory} // Set the category
        subcategory={subcategory}
        setSubcategory={setSubcategory} // Set the subcategory
        categories={categories} // Dynamically populated categories
        subcategories={subcategories} // Dynamically populated subcategories
      />

      <ProductGrid products={filteredProducts} openModal={openModal} />

      {selectedProduct && (
        <ProductModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Product Details"
          product={selectedProduct}
        />
      )}
    </div>
  );
};

export default AllProductsPage;
