// components/ProductGrid.js
import React from 'react';

const ProductGrid = ({ products, openModal }) => {
  return (
    <div className="product-section">
      <div className="product-section-inner">
        <div className="category-product-container">
          {products.map((product) => (
            <div key={product.id} className="category-product-item" onClick={() => openModal(product)}>
              <div style={{ cursor: 'pointer' }}>
                <img alt={product.name} src={product.images[0]}  className="product-image" />
                <div className="category-text-container">
                  <div className="category-left">{product.name}</div>
                  <div className="vertical-line">&nbsp;</div>
                  <div className="category-right">
                    ${product.default_price ? (product.default_price.unit_amount / 100).toFixed(2) : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductGrid;
