import React from 'react';
import '../styles/filtersort.css'; 


const FilterSort = ({sortOption, setSortOption, subcategory, setSubcategory, subcategories }) => {
  return (
    <div className="filter-section">

      {/* Sort By */}
      <div>
        <label>Sort By:</label>
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="default">Default</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
        </select>
      </div>

      {/* Subcategory Filter */}
      <div>
        <label>Category:</label>
        <select value={subcategory} onChange={(e) => setSubcategory(e.target.value)}>
          {subcategories.map((subcat, index) => (
            <option key={index} value={subcat}>
              {subcat}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FilterSort;
