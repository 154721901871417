import React from 'react';
import CartItem from '../components/Cart/CartItem';
import CartSummary from '../components/Cart/CartSummary';
import { useCart } from '../contexts/CartContext';
import GoBackComponent from '../components/GoBackComponent';
import '../styles/cart.css';

const CartPage = () => {
    const { cartItems } = useCart();

    return (
        <div className="cart-container">
            <GoBackComponent></GoBackComponent>
            <h1 className="cart-title">Shopping Cart!</h1>
            {cartItems.length === 0 ? (
                <p style={{ fontFamily: 'Sovereign, sans-serif', fontSize: '16px', color: '#D3AA1A' }}>
                    Your cart is empty.
                </p>
            ) : (
                <div className="cart-items">
                    {cartItems.map(item => (
                        <CartItem key={item.id} item={item} />
                    ))}
                </div>
            )}
            {cartItems.length > 0 && <CartSummary />}
        </div>
    );
}    

export default CartPage;
