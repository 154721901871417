import React, { useState } from 'react';
import GoBackComponent from '../components/GoBackComponent';
import '../styles/consult.css';
import emailjs from 'emailjs-com'; // Make sure emailjs is imported properly

// Ensure your environment variables are correctly referenced
const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const userID = process.env.REACT_APP_EMAILJS_USER_ID;

const ConsultPage = () => {

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Text');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if required fields are filled
    if (!name || !emailAddress || !phone || !contactMethod) {
      setError('Please fill all required fields');
      return;
    }

    // Construct template parameters for email
    const templateParams = {
      from_name: name,
      from_email: emailAddress,
      from_phone: phone,
      contact_method: contactMethod,
      message,
    };

    console.log('Sending email with parameters:', templateParams);

    // Send email using emailjs
    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setSuccess('Email sent successfully!');
        setError('');
        // Clear form fields on successful submission
        setName('');
        setEmailAddress('');
        setPhone('');
        setContactMethod('Text');
        setMessage('');
      })
      .catch((err) => {
        console.error('Error sending email:', err);
        setError('Failed to send email. Please try again later.');
        setSuccess('');
      });
  };

  return (
    <div className="consult-container">
      <GoBackComponent></GoBackComponent>
      <h1 className="consult-title">Request a Consultation</h1>
      
    {/* Image section */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="image">
                <img
                src="/images/mushroom.png" // Use direct path from the public folder
                alt="Mushroom"
                style={{ width: '375px', height: '450px' }}
            />
            </div>
        </div>
      <br /><br /><br />
      
      <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', color: '#D3AA1A' }}>
        Please fill out the form below and one of our mushroom professionals will get in touch.
      </p>
      <p style={{ textAlign: 'center' }}>
        Please fill out our consultation form, and one of our employees will get in touch with you based on your preferred form of contact.
        <br /><br />
        Our consultation fees are $100 per hour. To confirm your appointment, you will be sent a link to pay a $100 deposit fee. This deposit will be applied towards your consultation.
        <br /><br />
        Thank you and we look forward to hearing from you!
      </p>
      <br /><br />

      {/* Consultation Form */}
      <div>
        <h4 style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', color: '#4fa396', fontSize: '16px' }}>Consultation Application Form</h4>
        <br />
        {error && <p style={{ color: 'red', fontFamily: 'Bublest, sans-serif', fontSize: '60px' }}>{error}</p>}
        {success && (
          <p style={{ color: '#eb8050', fontFamily: 'Bublest, sans-serif', fontSize: '60px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}>
            {success}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <label style={{ textAlign: 'left', display: 'block', color: '#D3AA1A', fontSize: '16px' }}>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="First & Last Name"
            />
          </div>
          <div>
            <label style={{ textAlign: 'left', display: 'block', color: '#D3AA1A', fontSize: '16px' }}>Email:</label>
            <input
              type="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              required
              placeholder="youremail@email.com"
            />
          </div>
          <div>
            <label style={{ textAlign: 'left', display: 'block', color: '#D3AA1A', fontSize: '16px' }}>Phone:</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder="(123)-456-7890"
            />
          </div>
          <div>
            <label style={{ textAlign: 'left', display: 'block', color: '#D3AA1A', fontSize: '16px' }}>Best Form of Contact:</label>
            <div className="radio-group">
              <div>
                <input
                  type="radio"
                  id="contactText"
                  name="contactMethod"
                  value="Text"
                  checked={contactMethod === 'Text'}
                  onChange={(e) => setContactMethod(e.target.value)}
                  required
                />
                <label htmlFor="contactText" style={{ color: '#4fa396', fontSize: '14px' }}>Text</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="contactCall"
                  name="contactMethod"
                  value="Call"
                  checked={contactMethod === 'Call'}
                  onChange={(e) => setContactMethod(e.target.value)}
                />
                <label htmlFor="contactCall" style={{ color: '#4fa396', fontSize: '14px' }}>Call</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="contactEmail"
                  name="contactMethod"
                  value="Email"
                  checked={contactMethod === 'Email'}
                  onChange={(e) => setContactMethod(e.target.value)}
                />
                <label htmlFor="contactEmail" style={{ color: '#4fa396', fontSize: '14px' }}>Email</label>
              </div>
            </div>
          </div>
          <div>
            <label style={{ textAlign: 'left', display: 'block', color: '#D3AA1A', fontSize: '16px' }}>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
          </div>
          <p style={{ textAlign: 'center', fontSize: '12px' }}>
            *By confirming, you acknowledge the non-refundable deposit that you will be required to pay to secure your appointment. Details will be sent to you.
          </p>
          <button type="submit">Confirm</button>
        </form>
      </div>
    </div>
  );
};

export default ConsultPage;
