import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/emailsent.css';
import GoBackComponent from '../components/GoBackComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const EmailSent = () => {


  return (
    <div className="main-emailsent-container">
      <div className="emailsent-container">
      <GoBackComponent></GoBackComponent>
        <div className="product-text">
          <h3 style={{ textAlign: 'center', fontFamily: 'Bublest, sans-serif', color: '#D3AA1A' }}>Thank you for your message!</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='image'>
              <img src="/images/mushroom_2.png" alt="Mushroom" style={{ width: '325px', height: '400px' }} />
            </div>

          </div>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '16px' }}>We will get back to you as soon as possible.</p>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '16px' }}>For any questions or concerns regarding your order, please email us at <a href="mailto:dbmycosupply@gmail.com" style={{ color: '#4fa396' }}>dbmycosupply@gmail.com</a></p>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '28px' }}>Happy growing!</p>
          {/* Adjusted the style for the "Back to Cart" link */}
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '16px', marginTop: '20px' }}>
            <Link to="/" style={{ color: '#4fa396' }}>&larr; Home</Link>
          </p>
        </div> 
      </div>
    </div>  
  );
};

export default EmailSent;