import React from 'react';
import '../styles/filtersortall.css'; 

const FilterSortAll = ({ 
  sortOption, 
  setSortOption, 
  category, 
  setCategory, 
  categories = [], 
  subcategory, 
  setSubcategory, 
  subcategories = [] // Accept subcategories prop 
}) => {
  return (
    <div className="filter-section">
      {/* Sort By */}
      <div>
        <label>Sort By:</label>
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="default">Default</option>
          <option value="lowToHigh">Price: Low to High</option>
          <option value="highToLow">Price: High to Low</option>
        </select>
      </div>

      {/* Category Filter */}
      <div>
        <label>Category:</label>
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      {/* Subcategory Filter (only show if category is not "All") */}
      {category !== 'All' && (
        <div>
          <label>Products:</label>
          <select value={subcategory} onChange={(e) => setSubcategory(e.target.value)}>
            {subcategories.map((subcat) => (
              <option key={subcat} value={subcat}>
                {subcat}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default FilterSortAll;
