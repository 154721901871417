import React, { createContext, useContext, useReducer, useEffect } from 'react';

const CartContext = createContext();

const initialState = {
    cartItems: JSON.parse(localStorage.getItem('cartItems')) || [], // Load from localStorage or use an empty array
};

function cartReducer(state, action) {
    switch (action.type) {
        case 'ADD_ITEM':
            const existingItemIndex = state.cartItems.findIndex(
                item => item.id === action.payload.id && item.selectedPriceId === action.payload.selectedPriceId
            );
            if (existingItemIndex > -1) {
                const updatedCartItems = state.cartItems.map((item, index) =>
                    index === existingItemIndex
                        ? { ...item, quantity: item.quantity + action.payload.quantity }
                        : item
                );
                return { ...state, cartItems: updatedCartItems };
            }
            return { ...state, cartItems: [...state.cartItems, action.payload] };

        case 'REMOVE_ITEM':
            // Make sure both id and selectedPriceId are matched when removing an item
            return {
                ...state,
                cartItems: state.cartItems.filter(
                    item => !(item.id === action.payload.id && item.selectedPriceId === action.payload.selectedPriceId)
                )
            };

        case 'UPDATE_ITEM':
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.id === action.payload.id && item.selectedPriceId === action.payload.selectedPriceId
                        ? { ...item, quantity: action.payload.quantity }
                        : item
                ),
            };

        default:
            return state;
    }
}

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        // Whenever cartItems changes, save them to localStorage
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    }, [state.cartItems]);

    const addCartItem = (item) => {
        dispatch({ type: 'ADD_ITEM', payload: item });
    };

    const removeCartItem = (id, selectedPriceId) => {
        dispatch({ type: 'REMOVE_ITEM', payload: { id, selectedPriceId } }); // Pass both id and selectedPriceId
    };

    const updateCartItem = (id, selectedPriceId, quantity) => {
        dispatch({ type: 'UPDATE_ITEM', payload: { id, selectedPriceId, quantity } });
    };

    const value = {
        cartItems: state.cartItems,
        addCartItem,
        removeCartItem,
        updateCartItem,
    };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = () => useContext(CartContext);
