import React, { useState, useEffect } from 'react';
import ProductModal from '../components/ProductModal';
import FilterSort from '../components/FilterSort';
import ProductGrid from '../components/ProductGrid';
import '../styles/main.css';
import GoBackComponent from '../components/GoBackComponent';

const SampleMerchPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('default');
  const [subcategory, setSubcategory] = useState('All');
  const [subcategories, setSubcategories] = useState(['All']);

  // Sample product data for testing
  const sampleProducts = [
    {
      id: 'prod_123',
      name: 'Sample T-Shirt',
      description: 'A comfortable and stylish T-shirt.',
      images: ['/images/product_images/merch/shirt_1.jpg'], // Replace with valid URLs
      additional_images: JSON.stringify(['/images/product_images/merch/shirt_2.jpg']),
      default_price: {
        id: 'price_123',
        unit_amount: 2500, // Price in cents
        currency: 'usd',
        nickname: 'Standard Price',
      },
      prices: [
        {
          id: 'price_123',
          unit_amount: 2500,
          currency: 'usd',
          nickname: 'Standard Price',
        },
        {
          id: 'price_456',
          unit_amount: 3000,
          currency: 'usd',
          nickname: 'Premium Price',
        },
      ],
      category: 'Merch',
      subCategory: 'Clothing',
    },
  ];

  useEffect(() => {
    // Set sample products for testing
    setProducts(sampleProducts);
    setFilteredProducts(sampleProducts);
    setSubcategories(['All', 'Clothing']); // Example subcategories
  }, []);

  useEffect(() => {
    let updatedProducts = products.filter((product) => {
      const matchesSubcategory = subcategory === 'All' || product.subCategory === subcategory;
      return matchesSubcategory;
    });

    if (sortOption === 'lowToHigh') {
      updatedProducts.sort((a, b) => (a.default_price?.unit_amount || 0) - (b.default_price?.unit_amount || 0));
    } else if (sortOption === 'highToLow') {
      updatedProducts.sort((a, b) => (b.default_price?.unit_amount || 0) - (a.default_price?.unit_amount || 0));
    }

    setFilteredProducts(updatedProducts);
  }, [products, sortOption, subcategory]);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="main-container">
      <GoBackComponent />
      <h3 className="main-products-title">Merch</h3>

      <FilterSort
        sortOption={sortOption}
        setSortOption={setSortOption}
        subcategory={subcategory}
        setSubcategory={setSubcategory}
        subcategories={subcategories}
      />

      <ProductGrid products={filteredProducts} openModal={openModal} />

      {selectedProduct && (
        <ProductModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Product Details"
          product={selectedProduct}
        />
      )}
    </div>
  );
};

export default SampleMerchPage;
