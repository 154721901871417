import React from 'react';
import { useCart } from '../../contexts/CartContext';
import '../../styles/cartitem.css';

const CartItem = ({ item }) => {
    const { updateCartItem, removeCartItem } = useCart();

    const handleQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value, 10);

        // Ensure the quantity is at least 1
        if (newQuantity > 0) {
            updateCartItem(item.id, item.selectedPriceId, newQuantity);
        }
    };

    return (
<div className="cart-item">
    <div className="cart-item-left">
        <img src={item.image} alt={item.name} className="cart-item-img" />
        <div className="cart-item-info">
            <h4>{item.name}</h4>
            <div>
                <div>{item.priceNickname || item.defaultPriceNickname || 'No Nickname'}</div>
            </div>

            <div>Price: ${item.price.toFixed(2)}</div>
            <button onClick={() => removeCartItem(item.id, item.selectedPriceId)}>Remove</button>
        </div>
    </div>


            <div className="cart-item-right">
                <input
                    type="number"
                    value={item.quantity}
                    onChange={handleQuantityChange}
                    min="1"
                />
                <div>Total: ${(item.price * item.quantity).toFixed(2)}</div>
            </div>
        </div>
    );
};

export default CartItem;
