import React from 'react';
import { Link } from 'react-router-dom';
import GoBackComponent from '../components/GoBackComponent';
import '../styles/emailfail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const EmailFail = () => {
  return (
    <div className="main-emailfail-container">
      <div className="emailfail-container">
        <div className="product-text">
        <GoBackComponent></GoBackComponent>
          <h3 style={{ textAlign: 'center', fontFamily: 'Bublest, sans-serif', color: '#D3AA1A' }}>Oops!</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='image'>
                <img src="/images/mushroomImage_3.png" alt="Mushroom" style={{ width: '325px', height: '400px' }} />
            </div>

          </div>
          <p style={{ textAlign: 'center', fontFamily: 'Sovereign, sans-serif', fontSize: '18px' }}>
            Email Failed. Please try again or <Link to="/" style={{ color: '#4fa396' }}>click here</Link> to return to the form.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailFail;