import React, { useState } from 'react';
import '../styles/home.css';

const HomePage = () => {
    const [showShopLinks, setShowShopLinks] = useState(false);

    const toggleShopLinks = () => {
        setShowShopLinks(!showShopLinks);
    };

    const closeShopLinks = () => {
        console.log('Close button clicked');
        setShowShopLinks(false);
    };
    

    return (
        <div className="home-container">
            {/* Replace text with an image */}
            <img src="images/new_backround.png" alt="Home Image" className="home-image" />

            {/* Conditionally render "View Shop" button */}
            {!showShopLinks && (
                <button onClick={toggleShopLinks} className="view-shop-button">
                    View Shop
                </button>
            )}

            {/* Conditionally render shop product links */}
            {showShopLinks && (
                <div className="shop-links-container">
                    <div className="shop-links">
                        <a href="/all-products">All Products</a>
                        <a href="/growsupplies">Grow Supplies</a>
                        <a href="/labsupplies">Lab Supplies</a>
                        <a href="/merch">Merch</a>
                        <button onClick={closeShopLinks} className="close-shop-button">
                            X
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomePage;
